<template>
    <div ref="append-article">
        <zw-sidebar @shown="shown" :title="$t('bank.title.assign_incoming_invoice')">
            <div v-if="!loading" class="px-3 py-2">
                <b-card
                    border-variant="primary"
                    align="center"
                >
                    <b-card-text>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('bank.column.amount') }}:</strong></b-col>
                            <b-col class="text-left" cols="10">{{ payload.transfer.amount | priceFormat }}&euro;</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('bank.column.name') }}:</strong></b-col>
                            <b-col class="text-left" cols="10">{{ payload.transfer.name }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('bank.column.description') }}:</strong>
                            </b-col>
                            <b-col class="text-left" cols="10">{{ payload.transfer.description }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('bank.column.date') }}:</strong></b-col>
                            <b-col class="text-left" cols="10">
                                {{ payload.transfer.date_time | formatDate('DD.MM.YYYY') }}
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <validation-observer tag="div" ref="observer">
                    <b-row>
                        <b-col>
                            <zw-table ref="table"
                                      tableName="incomingInvoices"
                                      base-table="incoming_invoice"
                                      columnsPrefix="incoming_invoice.column."
                                      :customSource="getThis()"
                                      :items="myProvider"
                                      :sticky="false"
                                      sticky-header="100%"
                                      :fields="fields"
                                      :filter="filter"
                                      :selected="selected"
                                      :actions-list="getActions()"
                            >
                                <template #cell(actions)="row">
                                </template>
                            </zw-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button v-if="!allowMultiple" :disabled="selected.length!=1" block @click="onSubmit()"
                                      variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                            <b-button v-else :disabled="selected.length==0" block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
        </zw-sidebar>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from "@/bundles/erika_common_bundle/mixins/table-date-filter";

export default {
    name: 'AssignIncomingInvoiceModal',
    mixins: [commonSave, commonTable, tableDateFilter],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {},
            allowMultiple: false,
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('IncomingInvoices', ['getIncomingInvoicesTable']),
        shown() {
            this.$set(this, 'selected', [])
            this.form = JSON.parse(JSON.stringify(this.defaultForm))
            this.allowMultiple = this.payload.multiple || false

            this.loading = false
        },
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'IncomingInvoices/fetchIncomingInvoices', 'getIncomingInvoicesTable');
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    if (this.allowMultiple) {
                        this.form.incoming_invoice_ids = this.selected.map(row => row.id)
                    } else {
                        this.form.incoming_invoice_id = this.selected[0].id
                    }
                    this.form.id = this.payload.id

                    this.$store.dispatch('BankTransfers/assign', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        getActions() {
            return []
        }
    },
}
</script>